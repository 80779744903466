import {navigate, PageProps} from 'gatsby';
import {useQuery, useMutation, useApolloClient} from '@apollo/client';
import {
    Controller,
    FieldValues,
    useForm,
    UseFormReset,
    useWatch,
} from 'react-hook-form';
import {
    useEffect,
    useMemo,
    useState,
    useCallback,
} from 'react';
import {
    GetVideoBasicInfoDocument,
    GetVideoBasicInfoQuery,
    EditVideoBasicInfoDocument,
    VideoVersion,
    CheckYoutubeVideoIdExistsDocument,
    DeleteVideoDocument,
    EditVideoBasicInfoMutation,
    VideoUploadVersion,
} from '../../../../graphql-types';
import {
    Button,
    Checkbox,
    CopyTextIcon,
    Form,
    Input,
    InputPrefix,
    LoadingIndicator,
    Tabs,
    SelectBox,
    Textarea,
    WarningPopup,
    TagsInput,
} from '../../../../components/core';
import {Layout, StatusBar} from '../../../../components/layout';
import {DeleteIcon, NavArrowLeftIcon} from '../../../../components/core/icons';
import isDirtyForm from '../../../../helpers/form/isDirtyForm';
import pickDirtyFields from '../../../../helpers/form/pickDirtyFields';
import validateYoutubeVideoId from '../../../../helpers/validators/youtubeVideoId';
import toastify, {ToastLocationState} from '../../../../helpers/toast/toastify';
import {getVideoTabsData} from '../../../../configs/pages/videos/tabs';
import {nonvoMutationName, voMutationName} from '../../../../configs/pages/videos/copy';
import validateYoutubeVideoTags from '../../../../helpers/validators/youtubeVideoTags';
import * as layoutStyles from '../../../../components/layout/layout.module.css';
import * as videoStyles from './common.module.css';
import {getVideoURL} from '../../../../configs/pages/videos/videoUrl';

interface IResetVideoData<T extends Record<string, any>> {
    data: T,
    reset: UseFormReset<FieldValues>,
    dataName: keyof T,
}

async function resetVideoData<T extends Record<string, any>>({
    data,
    dataName,
    reset,
}: IResetVideoData<T>) {
    if (!data) {
        return;
    }

    reset({
        mcbt: data[dataName]?.mcbt,
        typeId: data[dataName]?.type?.id,
        channelId: data[dataName]?.channel.id,
        sku: data[dataName]?.sku,
        themeId: data[dataName]?.theme?.id,
        addAssetLabels: data[dataName]?.addAssetLabels,
        isMadeForKids: typeof data[dataName]?.isMadeForKids === 'boolean' ? data[dataName]?.isMadeForKids : false,
        note: data[dataName]?.note,
        upload: {
            notifySubscribers: false,
            uploadPolicyId: data[dataName]?.upload?.uploadPolicy?.id,
            privacy: data[dataName]?.upload?.privacy,
            videoId: data[dataName]?.upload?.videoId,
            categoryId: data[dataName]?.upload?.category?.id,
            customThumbnail: data[dataName]?.upload?.customThumbnail,
            filename: data[dataName]?.upload?.filename,
            customId: data[dataName]?.upload?.customId,
            keywords: data[dataName]?.upload?.keywords,
            uploadVersion: data[dataName]?.upload?.uploadVersion,
            seriesGroupId: data[dataName]?.upload?.seriesGroup?.id,
            seasonSubgroupId: data[dataName]?.upload?.seasonSubgroup?.id,
        },
    });
}

type GetVideoBasicInfoEditPageType = {
    video: string,
};
type GetVideoBasicInfoEditPageTypeProps = PageProps<null, null, ToastLocationState> & GetVideoBasicInfoEditPageType;

const VideoBasicInfoEditPage = ({
    video: videoId,
    location,
}: GetVideoBasicInfoEditPageTypeProps) => {
    const apolloClient = useApolloClient();
    const {loading, data: videoBasicInfo} = useQuery(
        GetVideoBasicInfoDocument,
        {variables: {videoId}},
    );

    const videoTabsData = useMemo(() => getVideoTabsData(videoBasicInfo?.video?.version), [videoBasicInfo]);
    const [editVideo, {loading: isSendingEdit}] = useMutation(EditVideoBasicInfoDocument);
    const [deleteVideo, {loading: isSendingDelete}] = useMutation(DeleteVideoDocument);
    const formMethods = useForm<FieldValues>({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {upload: {}},
    });
    const {
        control,
        reset,
        handleSubmit,
        formState: {
            dirtyFields,
        },
    } = formMethods;
    const [discardWarningDisplayed, setDiscardWarningDisplayed] = useState(false);
    const [onDiscardWarningConfirm, setOnDiscardWarningConfirm] = useState<() => () => void>(() => () => {});
    const [deleteWarningDisplayed, setDeleteWarningDisplayed] = useState(false);
    const [onDeleteWarningConfirm, setOnDeleteWarningConfirm] = useState<() => () => void>(() => () => {});
    const isDirty = isDirtyForm(dirtyFields);
    const isNonVO = videoBasicInfo?.video?.version === VideoVersion.Translation;
    const videoTitle = videoBasicInfo ? videoBasicInfo.video?.mcbt : videoId;
    const pageTitle = `Video | ${videoTitle}`;
    const [videoIdToCopy, uploadVersion] = useWatch({control, name: ['upload.videoId', 'upload.uploadVersion']});

    const interceptLinks = {
        status: !!(isDirty),
        action: () => {
            setDiscardWarningDisplayed(true);
        },
        setOnConfirm: setOnDiscardWarningConfirm,
    };

    useEffect(() => {
        if (!videoBasicInfo) {
            return;
        }

        resetVideoData<GetVideoBasicInfoQuery>({
            data: videoBasicInfo,
            dataName: 'video',
            reset,
        });
    }, [reset, videoBasicInfo]);

    const onSubmit = (formData: FieldValues) => {
        const newData = pickDirtyFields(formData, dirtyFields);

        editVideo({
            variables: {
                editVideoId: videoId,
                input: newData,
            },
            onCompleted(editData) {
                if (!editData) {
                    toastify({
                        type: 'error',
                        text: 'There was an error on video update',
                    });

                    return;
                }

                resetVideoData<EditVideoBasicInfoMutation>({
                    data: editData,
                    dataName: 'editVideo',
                    reset,
                });

                toastify({
                    type: 'success',
                    text: 'Video basic info updated',
                });
            },
            onError(err) {
                toastify({
                    type: 'error',
                    text: `There was an error on video update: ${err.message}`,
                });
            },
            onQueryUpdated(observableQuery) {
                return observableQuery.refetch();
            },
        });
    };

    const handleDeleteVideo = useCallback(() => {
        setOnDeleteWarningConfirm(() => () => {
            deleteVideo({
                variables: {
                    deleteVideoId: videoId,
                },
                onCompleted() {
                    setDeleteWarningDisplayed(false);

                    navigate('../', {
                        state: {
                            toast: {
                                type: 'success',
                                text: 'Video deleted',
                            },
                        },
                    });
                },
                onError(err) {
                    toastify({
                        type: 'error',
                        text: `There was an error on video delete: ${err.message}`,
                    });
                },
                onQueryUpdated(observableQuery) {
                    return observableQuery.refetch();
                },
            });
        });

        setDeleteWarningDisplayed(true);
    }, [deleteVideo, videoId]);
    const videoURLBase = useMemo(() => getVideoURL('', uploadVersion), [uploadVersion]);

    useEffect(() => {
        if (!location?.state?.toast) {
            return;
        }

        toastify(location.state.toast);
        window.history.replaceState(null, '');
    }, [location?.state?.toast]);

    return (
        <>
            <Layout
                title={pageTitle}
                interceptLinks={interceptLinks}
                scope="videos.write"
            >
                {loading && (
                    <LoadingIndicator/>
                )}
                <div className={layoutStyles.pageHeader}>
                    <StatusBar
                        buttons={(
                            <>
                                <Button
                                    icon={NavArrowLeftIcon}
                                    text="Back to videos"
                                    onClick={() => {
                                        const to = '../';

                                        if (isDirty) {
                                            setOnDiscardWarningConfirm(() => () => {
                                                navigate(to);
                                            });
                                            setDiscardWarningDisplayed(true);

                                            return;
                                        }

                                        navigate(to);
                                    }}
                                    variant="outline"
                                />
                                <Button
                                    text="Save changes"
                                    onClick={handleSubmit(onSubmit)}
                                    disabled={!isDirty || isSendingEdit === true}
                                    loading={isSendingEdit === true}
                                />
                            </>
                        )}
                        title={(
                            <>
                                <span>Video /&nbsp;</span>
                                <strong>{videoTitle}</strong>
                            </>
                        )}
                    />
                </div>
                <div>
                    <Tabs
                        data={videoTabsData}
                        interceptLinks={interceptLinks}
                    />
                </div>
                <div className={layoutStyles.pageContent}>
                    {videoBasicInfo && (
                        <>
                            <h2>Basic info ({isNonVO ? nonvoMutationName : voMutationName})</h2>
                            <Form methods={formMethods}>
                                <Form.Row>
                                    <Form.Column hasHalfWidth={true}>
                                        <Input
                                            title="MCBT/AID"
                                            name="mcbt"
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            disabled={true}
                                        />
                                    </Form.Column>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Column>
                                        <SelectBox
                                            title="Type"
                                            placeholder="Choose the type"
                                            control={control}
                                            controllerName="typeId"
                                            optionsValues={
                                                videoBasicInfo.typeAttributes.map(type => ({
                                                    id: type?.id,
                                                    value: type?.id,
                                                    label: type?.name,
                                                }))
                                            }
                                            canType={true}
                                        />
                                    </Form.Column>
                                    <Form.Column>
                                        <SelectBox
                                            title="Channel"
                                            placeholder="Choose the channel"
                                            control={control}
                                            controllerName="channelId"
                                            optionsValues={
                                                videoBasicInfo.channels.map(channel => ({
                                                    id: channel?.id,
                                                    value: channel?.id,
                                                    label: channel?.name,
                                                }))
                                            }
                                            isDisabled={true}
                                        />
                                    </Form.Column>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Column>
                                        <SelectBox
                                            title="Theme"
                                            placeholder="Choose the theme"
                                            control={control}
                                            controllerName="themeId"
                                            optionsValues={
                                                videoBasicInfo.themeAttributes.map(theme => ({
                                                    id: theme?.id,
                                                    value: theme?.id,
                                                    label: theme?.name,
                                                }))
                                            }
                                            canType={true}
                                        />
                                    </Form.Column>
                                    <Form.Column>
                                        <TagsInput
                                            title="SKU"
                                            name="sku"
                                            control={control}
                                            placeholder={'Type SKU and press "Enter" or "comma"'}
                                            rules={{
                                                validate: value => {
                                                    if (value.length > 200) {
                                                        return 'Maximum 200 characters allowed';
                                                    }

                                                    return true;
                                                },
                                            }}
                                        />
                                    </Form.Column>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Column>
                                        <Input
                                            title="Add asset labels"
                                            name="addAssetLabels"
                                            control={control}
                                            rules={{
                                                maxLength: {
                                                    value: 255,
                                                    message: 'It can\'t be longer then 255 characters',
                                                },
                                            }}
                                        />
                                    </Form.Column>
                                    <Form.Column alignVertical="center">
                                        <Form.Row>
                                            <Form.Column>
                                                <Controller
                                                    control={control}
                                                    name="isMadeForKids"
                                                    render={({
                                                        field: {
                                                            onChange,
                                                            value,
                                                        },
                                                    }) => (
                                                        <Checkbox
                                                            name="isMadeForKids"
                                                            label="Is made for kids"
                                                            checked={value}
                                                            handleChange={() => {
                                                                onChange(value !== true);
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Form.Column>
                                            <Form.Column>
                                                <>
                                                    {isNonVO && (
                                                        <Controller
                                                            control={control}
                                                            name="upload.notifySubscribers"
                                                            render={({
                                                                field: {
                                                                    onChange,
                                                                    value,
                                                                },
                                                            }) => (
                                                                <Checkbox
                                                                    name="upload.notifySubscribers"
                                                                    label="Notify subscribers"
                                                                    checked={value}
                                                                    handleChange={() => {
                                                                        onChange(value !== true);
                                                                    }}
                                                                    disabled={true}
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                </>
                                            </Form.Column>
                                        </Form.Row>
                                    </Form.Column>
                                </Form.Row>
                                {isNonVO && (
                                    <>
                                        <Form.Row>
                                            <Form.Column>
                                                <SelectBox
                                                    title="Upload version"
                                                    placeholder="Choose the upload version"
                                                    control={control}
                                                    controllerName="upload.uploadVersion"
                                                    optionsValues={
                                                        (Object.values(VideoUploadVersion) as Array<VideoUploadVersion>)
                                                            .map(uploadVersionValue => ({
                                                                id: uploadVersionValue,
                                                                value: uploadVersionValue,
                                                                label: uploadVersionValue,
                                                            }))
                                                    }
                                                />
                                            </Form.Column>
                                            <Form.Column>
                                                <InputPrefix
                                                    prefix={videoURLBase}
                                                    title="Video ID"
                                                    icon={(
                                                        <CopyTextIcon
                                                            textToCopy={videoURLBase + videoIdToCopy}
                                                            tooltip="Copy full video URL"
                                                            isInputPrefix={true}
                                                        />
                                                    )}
                                                >
                                                    <Input
                                                        name="upload.videoId"
                                                        control={control}
                                                        rules={{
                                                            validate: async value => {
                                                                if (value === '' || videoBasicInfo.video?.upload?.videoId === value) {
                                                                    return true;
                                                                }

                                                                const patternValidation = validateYoutubeVideoId(value);

                                                                if (typeof patternValidation === 'string') {
                                                                    return patternValidation;
                                                                }

                                                                const {data: {uploadVideoIdExists}} = await apolloClient.query({
                                                                    query: CheckYoutubeVideoIdExistsDocument,
                                                                    variables: {videoId: value},
                                                                });

                                                                if (uploadVideoIdExists === true) {
                                                                    return 'This Youtube ID is already in use';
                                                                }

                                                                return true;
                                                            },
                                                        }}
                                                    />
                                                </InputPrefix>
                                            </Form.Column>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Column>
                                                <SelectBox
                                                    title="Upload policy"
                                                    placeholder="Choose the upload policy"
                                                    control={control}
                                                    controllerName="upload.uploadPolicyId"
                                                    optionsValues={
                                                        videoBasicInfo.uploadPolicyAttributes.map(uploadPolicy => ({
                                                            id: uploadPolicy?.id,
                                                            value: uploadPolicy?.id,
                                                            label: uploadPolicy?.name,
                                                        }))
                                                    }
                                                    canType={true}
                                                />
                                            </Form.Column>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Column>
                                                <Input
                                                    title="Filename"
                                                    name="upload.filename"
                                                    control={control}
                                                    rules={{
                                                        pattern: {
                                                            value: /^.*\.(mp4|mov|avi)$/,
                                                            message: 'Not valid video file format (.mp4, .mov or .avi only)',
                                                        },
                                                        maxLength: {
                                                            value: 255,
                                                            message: 'It can\'t be longer then 255 characters',
                                                        },
                                                    }}
                                                />
                                            </Form.Column>
                                            <Form.Column>
                                                <Input
                                                    title="Custom ID"
                                                    name="upload.customId"
                                                    control={control}
                                                    rules={{
                                                        maxLength: {
                                                            value: 255,
                                                            message: 'Custom ID can\'t be longer then 255 characters',
                                                        },
                                                    }}
                                                />
                                            </Form.Column>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Column>
                                                <Input
                                                    title="Custom thumbnail"
                                                    name="upload.customThumbnail"
                                                    control={control}
                                                    rules={{
                                                        pattern: {
                                                            value: /^.*\.(jpg|png|jpeg|gif)$/,
                                                            message: 'Not valid image file format (.jpg, .jpeg, .png or .gif)',
                                                        },
                                                        maxLength: {
                                                            value: 255,
                                                            message: 'It can\'t be longer then 255 characters',
                                                        },
                                                    }}
                                                />
                                            </Form.Column>
                                            <Form.Column>
                                                {videoIdToCopy && videoIdToCopy !== '' && (
                                                    <div className={videoStyles.thumbnailPreview}>
                                                        <img
                                                            src={`https://i.ytimg.com/vi/${videoIdToCopy}/0.jpg`}
                                                            alt=""
                                                        />
                                                    </div>
                                                )}
                                            </Form.Column>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Column>
                                                <SelectBox
                                                    title="Category"
                                                    placeholder="Choose the category"
                                                    control={control}
                                                    controllerName="upload.categoryId"
                                                    optionsValues={
                                                        videoBasicInfo.categoryAttributes.map(category => ({
                                                            id: category?.id,
                                                            value: category?.id,
                                                            label: category?.name,
                                                        }))
                                                    }
                                                />
                                            </Form.Column>
                                            <Form.Column>
                                                <SelectBox
                                                    title="Privacy"
                                                    placeholder="Choose the privacy"
                                                    control={control}
                                                    controllerName="upload.privacy"
                                                    optionsValues={
                                                        videoBasicInfo.privacyAttributes.map(privacy => ({
                                                            id: privacy?.id,
                                                            value: privacy?.name,
                                                            label: `${privacy?.name.charAt(0).toUpperCase()}${privacy?.name.slice(1)}`,
                                                        }))
                                                    }
                                                />
                                            </Form.Column>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Column hasHalfWidth={true}>
                                                <TagsInput
                                                    title="KWs (tags)"
                                                    name="upload.keywords"
                                                    control={control}
                                                    placeholder={'Type tag and press "Enter" or "comma"'}
                                                    rules={{
                                                        validate: value => validateYoutubeVideoTags(value),
                                                    }}
                                                />
                                            </Form.Column>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Column>
                                                <SelectBox
                                                    title="Series/Group"
                                                    placeholder="Choose the Series/Group"
                                                    control={control}
                                                    controllerName="upload.seriesGroupId"
                                                    optionsValues={
                                                        videoBasicInfo?.seriesGroupAttributes.map(seriesGroup => ({
                                                            id: seriesGroup?.id,
                                                            value: seriesGroup?.id,
                                                            label: seriesGroup?.name,
                                                        }))
                                                    }
                                                    canType={true}
                                                />
                                            </Form.Column>
                                            <Form.Column>
                                                <SelectBox
                                                    title="Season/Subgroup"
                                                    placeholder="Choose the Season/Subgroup"
                                                    control={control}
                                                    controllerName="upload.seasonSubgroupId"
                                                    optionsValues={
                                                        videoBasicInfo?.seasonSubgroupAttributes.map(seasonSubgroup => ({
                                                            id: seasonSubgroup?.id,
                                                            value: seasonSubgroup?.id,
                                                            label: seasonSubgroup?.name,
                                                        }))
                                                    }
                                                    canType={true}
                                                />
                                            </Form.Column>
                                        </Form.Row>
                                    </>
                                )}
                                <Form.Row>
                                    <Form.Column>
                                        <Textarea
                                            control={control}
                                            name="note"
                                            title="Notes"
                                            rules={{
                                                maxLength: {
                                                    value: 255,
                                                    message: 'Notes can\'t be longer then 255 characters',
                                                },
                                            }}
                                        />
                                    </Form.Column>
                                </Form.Row>
                            </Form>
                            <div className={layoutStyles.pageSection}>
                                <h3>Danger zone</h3>
                                <Button
                                    text={'Delete whole video'}
                                    color="error"
                                    onClick={handleDeleteVideo}
                                    disabled={isSendingEdit}
                                    icon={DeleteIcon}
                                />
                            </div>
                        </>
                    )}
                </div>
            </Layout>
            {discardWarningDisplayed && (
                <WarningPopup
                    topText={[
                        'Whoa, wait!',
                        <br key="linebreak" />,
                        'You are about to discard all the changes. Do you really want to do that?',
                    ]}
                    buttons={(
                        <>
                            <Button
                                text="Yes, discard all changes"
                                onClick={onDiscardWarningConfirm}
                                color={'error'}
                            />
                            <Button
                                text="No, take me back to editing"
                                onClick={() => {
                                    setDiscardWarningDisplayed(false);
                                }}
                            />
                        </>
                    )}
                />
            )}
            {deleteWarningDisplayed && (
                <WarningPopup
                    topText={'Do you really wanna delete the whole video, including all mutations?'}
                    buttons={(
                        <>
                            <Button
                                text="Yes, delete it"
                                onClick={onDeleteWarningConfirm}
                                color={'error'}
                                disabled={isSendingDelete}
                                loading={isSendingDelete}
                            />
                            <Button
                                text="No, keep it"
                                onClick={() => {
                                    setDeleteWarningDisplayed(false);
                                }}
                            />
                        </>
                    )}
                />
            )}
        </>
    );
};

export default VideoBasicInfoEditPage;
